<template lang="pug">
.rootAu
  //- (:style="{ position: 'relative', height: UsableHeight, maxWidth: UsableWidth, margin: '0px auto' }")
  Home(v-if="view == 'Home'" :inPresentation="inPresentation")
  TurnChecker(v-if="view == 'TurnChecker'")
  Autenticate(v-if="view == 'Autenticate' || view == 'NewUser' || view == 'IsOlder' || view == 'IsOlderSecond'" :newUser="view == 'NewUser'")
  UploadFiles(v-if="view == 'UploadFiles'")
  IsOlder(v-if="view == 'IsOlder'")
  IsOlderSecond(v-if="view == 'IsOlderSecond'")
  VerificationData(v-if="view == 'VerificationData'")
  ConfirmUpload(v-if="view == 'ConfirmUpload'")
  ErrorAutenticate(v-if="view == 'ErrorAutenticate'")
  ErrorAutenticateVal(v-if="view == 'ErrorAutenticateVal'")
  BranchValidation(v-if="view == 'BranchValidation'")
  OutOperationsFinish(v-if="view == 'OutOperationsFinish'")
  Afiliate(v-if="view == 'Afiliate'")
  NewUser(v-if="view == 'NewUser'")
  Presentation(v-if="view == 'Home'" ref="Presentation" @inPresentation="setPresentation")
  //- PresentationImage(v-if="view == 'Home'" @inPresentation="setPresentation")
  AreYouThere(v-if="showAreYouThere && view === 'Turno'" @restartAreYouThere="restartAreYouThere")
  AreYouThere(v-if="showAreYouThere && view === 'UploadFiles'" @restartAreYouThere="restartAreYouThere")
  ModalOutHourContinue(v-if="!HorarioOutContinue && view == 'Home'")
  p.Version versión: {{ version }}
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import EvalMobile from "@/utils/evalmobile";
// views
import Home from "./Home";
import TurnChecker from "./TurnChecker";
import Autenticate from "./Autenticate";
import UploadFiles from "./UploadFiles";
import VerificationData from "./VerificationData";
import ConfirmUpload from "./ConfirmUpload";
import ErrorAutenticate from "./ErrorAutenticate";
import BranchValidation from "./BranchValidation";
import OutOperationsFinish from "./OutOperationsFinish";
import ErrorAutenticateVal from "./ErrorAutenticateVal";
import NewUser from "./NewUser";
import Afiliate from "./Afiliate";
import IsOlder from "./IsOlder";
import IsOlderSecond from "./IsOlderSecond";
import Presentation from "./Presentation.vue";
import AreYouThere from "./components/AreYouThere.vue";
import ModalOutHourContinue from "./components/ModalOutHourContinue";

import onScan from "onscan.js";
import $ from "jquery";
import LS from "../../helpers/localStorage";

export default {
  name: "autoservice",
  components: {
    Home,
    TurnChecker,
    Autenticate,
    UploadFiles,
    ConfirmUpload,
    IsOlder,
    VerificationData,
    ErrorAutenticate,
    BranchValidation,
    OutOperationsFinish,
    NewUser,
    Afiliate,
    IsOlderSecond,
    ErrorAutenticateVal,
    Presentation,
    AreYouThere,
    ModalOutHourContinue
  },
  data() {
    return {
      inPresentation: false,
      showAreYouThere: false,
      counterOldest: 0
    };
  },
  created(){

    /* eslint-disable */
    console.log("Scanner");

    let vue = this;

    $(document).keydown(function(e) {
      // var key = e.Code || e.keyCode;
      // enter key do nothing
      e.preventDefault();
      vue.$refs.Presentation.stopPresentation();
      vue.setExplanation(true);
      vue.onStopTimer(false);
    });

    // Initialize with options
    onScan.attachTo(document, {
        suffixKeyCodes: [13], // enter-key expected at the end of a scan
        reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
        onScan: function(sCode) { // Alternative to document.addEventListener('scan')
          console.log("sCode")
          console.log(sCode)
          if(sCode){
            vue.isLoading = true;
            var re = /[0-9]{10}[A-Z]{3}/g;
            let ced = re.exec(sCode);
            if(ced){
              LS.setItem("scanCed", parseInt(ced[0]))
            }
            vue.isLoading = false;
          }

        },
        // onKeyDetect: function(iKeyCode){ // output all potentially relevant key events - great for debugging!
        //     console.log('Pressed: ' + iKeyCode);
        // }
    });      

    /* eslint-enable */
  },
  mounted() {
    console.warn(this.HorarioOutContinue);
    // tracking
    let trackingData = {
      uuid: window.person.id,
      accion: "Customer - Inicio",
      debmedia_turn_code: "",
      url_origen: window.location.href,
      origen: "ad",
      mensajes: ""
    };

    this.sendTracking(trackingData);

    // Timer

    this.setState({
      key: "containerImageDocs",
      value: document.querySelector(".containerImageDocs")
    });

    this.startTimer({});

    document.addEventListener("click", this.onStartTimerFunc);
  },
  destroyed() {
    document.removeEventListener("click", this.onStartTimerFunc);
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  watch: {
    timer(val) {
      if (!val) {
        document.removeEventListener("click", this.onStartTimerFunc);
        document.addEventListener("click", this.onStartTimerFunc);
      }
    },
    view(val) {
      console.warn(val);
      if (val && val === "IsOlder") {
        this.counterOldest++;
      } else if (
        val &&
        val !== "IsOlder" &&
        val !== "Autenticate" &&
        val !== "IsOlderSecond"
      ) {
        this.counterOldest = 0;
      }
    },
    counterOldest(val) {
      if (val > 1) {
        this.goToView({ view: "IsOlderSecond" });
      }
    }
  },
  computed: {
    ...mapState({
      view: state => state.virtualrow_atril_video.view,
      explanation: state => state.virtualrow_atril_video.explanation,
      stopTimer: state => state.autoservice.stopTimer,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer,
      HorarioOutContinue: state => state.branchInfo.activeBranch,
      version: state => state.version
    }),

    UsableHeight() {
      return window.innerHeight + "px";
    },
    UsableWidth() {
      return EvalMobile.any() ? "100vw" : "500px";
    }
  },
  methods: {
    ...mapActions({
      sendTracking: "virtualrow_atril_video/handleTracking",

      // Timer
      goToView: "virtualrow_atril_video/goToView",
      setExplanation: "virtualrow_atril_video/setExplanation",
      onStopTimer: "autoservice/onStopTimer"
    }),
    ...mapMutations({
      setState: "virtualrow_atril_video/setState"
    }),
    setPresentation(value) {
      this.inPresentation = value;
    },
    restartAreYouThere() {
      this.showAreYouThere = false;
    },
    onStartTimerFunc() {
      this.setState({ key: "showProgressBar", value: false });

      this.setState({
        key: "containerImageDocs",
        value: document.querySelector(".containerImageDocs")
      });
      const timeout = setTimeout(() => {
        this.setState({ key: "showProgressBar", value: true });
        this.startTimer();

        clearTimeout(timeout);
      }, 10);
    },
    startTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.setState({ key: "timer", value: null });
      }

      if (this.view === "UploadFiles") {
        this.setState({
          key: "timer",
          value: setTimeout(() => {
            // this.goToHome();
            this.showAreYouThere = true;
            clearTimeout(this.timer);
            this.setState({ key: "timer", value: null });
          }, 40000)
        });
      } else {
        this.setState({
          key: "timer",
          value: setTimeout(() => {
            this.goToHome();
            this.showAreYouThere = true;
            clearTimeout(this.timer);
            this.setState({ key: "timer", value: null });
          }, 30000)
        });
      }
    },
    goToHome() {
      this.onStopTimer(true);
      this.goToView({ view: "Home" });
      this.setExplanation(true);
    }
  }
};
</script>

<style>
.rootAu {
  max-width: 825px;
  margin: auto;
}

.cropper-canvas-without-crop {
  transform: none !important;
}

/* .cropper-canvas {
  transition: 0.1s all;
} */

.buttonText {
  font-size: 28px;
}

.Subtitle {
  font-size: 35px;
}

.Title {
  font-size: 38px;
  font-weight: bold;
  color: var(--color-primary);
}

.fontBack {
  font-size: 40px;
}

.Page {
  position: fixed;
  bottom: 105px;
  height: calc(50vh - 105px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#small-modal-dk___BV_modal_outer_ {
  z-index: 989000000000089000 !important;
}

.modal-body {
  padding: 50px !important;
}

.TimerContainer {
  position: fixed;
  right: 238px;
  top: 48%;
  z-index: 13;
  transform: translateY(-50%);
  background: white;
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content,
#small-modal-dk___BV_modal_content_,
#new-turn-modal___BV_modal_content_ {
  width: 640px;
  /* margin: 10px; */
  position: absolute;
  margin: auto;
  bottom: 105px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999;
}

#small-modal-dk___BV_modal_content_ {
  width: 677px;
}

#timer-init___BV_modal_content_ {
  top: 35%;
  width: 765px;
  max-width: 765px;
  height: fit-content;
  position: absolute;
  margin: auto;
  bottom: 105px;
  transform: translateX(-50%);
}

#small-modal-dk-over___BV_modal_content_ {
  max-width: 640px;
  /* margin: 10px; */
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}

#small-modal-dk-over___BV_modal_content_ {
  top: 55% !important;
  transform: translateX(-50%);
  height: fit-content;
}

#small-modal-dk___BV_modal_content_,
#new-turn-modal___BV_modal_content_ {
  bottom: auto;
  top: 73%;
  transform: translate(-50%, -50%);
}

#autenticate___BV_modal_content_ {
  width: 898px;
  max-width: 769px;
}

html {
  overflow: hidden;
}

.cropper-point.point-se {
  width: 25px !important;
  height: 25px !important;
}

.cropper-point {
  width: 20px !important;
  height: 20px !important;
}

.cropper-canvas {
  width: 100% !important;
}

.cropper-canvas img.rotateWidth {
  width: 448px !important;
}

.cropper-canvas img.rotateWidthCancel {
  width: 100% !important;
}

.Version {
  position: absolute;
  top: 98%;
  right: 5px;

  font-size: 0.9rem;

  color: var(--color-primary);
}

/* .cropper-canvas img { */
/* width: 100% !important; */
/* height: fit-content !important;
}

.cropper-canvas img {
  height: 846.4px !important;
} */

/* #ShowImage___BV_modal_content_ {
  width: 100vw;
} */
</style>
