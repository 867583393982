<template lang="pug">
.modal_full(v-show="showPresentation && localFiles.length>0")
  .message_important(v-if="showMessage")
    .position-relative
      img(src="@/assets/kiosco46/touchScreen.png" alt="touchScreen").touchScreen
      img(src="@/assets/kiosco46/clic.gif" alt="clic").clicGif
    //- p.Title
    //-   strong(style="color: #E2574C;") Toca
    //-   span la
    //-   strong(style="color: #E2574C;") pantalla
    //-   span para iniciar
    //- p.Title a este servicio
  img(
    v-if="item && item.name && item.name.split('.')[1] != 'mp4'",
    :src="item.src",
    style="width: -webkit-fill-available; height: -webkit-fill-available"
  )
  video(
    :id="item.id",
    v-if="item && item.name && item.name.split('.')[1] == 'mp4'",
    :src="item.src",
    autoplay,
    muted,
    style="width: -webkit-fill-available; height: -webkit-fill-available"
  )
</template>

<script>
import axios from "axios";
import _ from "underscore";
import { mapState } from "vuex";

var AWS = require("aws-sdk");

export default {
  name: "Presentation_Autoservice",
  data() {
    return {
      s3: null,
      indexDb: null,
      bucketName: "",
      dbconnect: null,
      dbObject: null,
      localFiles: [],
      init: true,
      item: {},
      indexElement: 0,
      showPresentation: true,
      timer: null,
      timerBox: null,
      counter_box: 0,
      showMessage: false
    };
  },
  destroyed() {
    if (this.timerBox) clearInterval(this.timerBox);
  },
  watch: {
    showMessage(val) {
      if (val) {
        this.timerFade = setTimeout(() => {
          this.showMessage = false;
          clearTimeout(this.timerFade);
        }, 5000);
      }
    }
  },
  created() {
    // start timer showed
    this.showMessage = true;
    this.timerBox = setInterval(() => {
      this.counter_box++;
      if (this.counter_box === 15) {
        this.showMessage = true;
        this.counter_box = 0;
      }
    }, 1000);

    // get objects from s3
    AWS.config.region = "us-east-1"; // Región
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-east-1:4a643041-abf5-4076-b285-d3b092e1c8c2"
    });

    // initia s3
    this.s3 = new AWS.S3();
    this.bucketName = "colmedica-presentations-stand";

    // IndexedDB
    // Check ¡IndexedDB API
    if (!("indexedDB" in window)) {
      alert("¡IndexedDB no es compatible!");
    }

    // save blob files on device
    this.dbconnect = window.indexedDB.open("files_local_db", 1);

    // For future use. Currently only in latest Firefox versions
    this.dbconnect.onupgradeneeded = function(event) {
      console.log("Se creó la BD");

      var db = event.target.result;

      var objectStore = db.createObjectStore("files", {
        keyPath: "id",
        autoIncrement: true
      });
      objectStore.createIndex("name", "name", { unique: true });
      objectStore.createIndex("blob", "blob", { unique: false });
      objectStore.createIndex("created", "created", { unique: false });
    };

    this.dbconnect.onsuccess = env => {
      console.log("BD-Actualización exitosa");
      this.dbObject = env.target.result;

      // init rigth now
      if (this.init) {
        this.playCarrousel(true);
        this.init = false;
      } else {
        // init before x time
        setTimeout(() => {
          this.playCarrousel(false);
        }, 15000);
      }
    };

    // Touch event
    let vue = this;
    window.addEventListener(
      "touchstart",
      function onFirstTouch() {
        vue.showPresentation = false;
        setTimeout(() => {
          vue.$emit("inPresentation", false);
        }, 500);
        if (vue.timer) clearTimeout(vue.timer);
        vue.timer = setTimeout(() => {
          vue.showPresentation = true;
          vue.$emit("inPresentation", true);
          vue.playCarrousel(false);
        }, 10000);
      },
      false
    );

    // Click event
    document.onclick = () => {
      vue.showPresentation = false;
      setTimeout(() => {
        vue.$emit("inPresentation", false);
      }, 500);
      if (vue.timer) clearTimeout(vue.timer);
      vue.timer = setTimeout(() => {
        vue.showPresentation = true;
        vue.$emit("inPresentation", true);
        // params reset
        vue.playCarrousel(false);
      }, 10000);
    };
  },

  computed: {
    ...mapState({
      stopTimer: state => state.autoservice.stopTimer,
      view: state => state.virtualrow_atril_video.view
    })
  },

  methods: {
    // sync files from s3 to local
    syncFiles() {
      return new Promise(resolve => {
        let prefix = ``;

        var params = {
          Bucket: this.bucketName,
          Delimiter: "",
          Prefix: prefix
        };

        let vue = this;
        let files_s3 = [];

        this.s3.listObjects(params, async function(err, data) {
          if (!err) {
            // Files from s3
            files_s3 = data.Contents;
          }

          // Init local database
          var transaction = vue.dbObject.transaction(["files"], "readwrite");

          // START TRANSACTION ON LOCAL
          var filesStored = transaction.objectStore("files");

          // Fetch local files
          var request = filesStored.getAll();
          request.onerror = function(event) {
            // Handle errors!
            console.log(event);
          };
          request.onsuccess = async () => {
            // Do something with the request.result!
            let files = request.result;

            // remove all local files
            if (!err) {
              files.map(f => {
                if (files_s3.map(s => s.Key).includes(f.name)) return;
                var objectStoreR = vue.dbObject
                  .transaction(["files"], "readwrite")
                  .objectStore("files");
                objectStoreR.delete(f.id);
              });
            }

            // save local data from s3
            if (files_s3.length > 0 && !err) {
              let index = 0;
              await new Promise(resolve => {
                vue.downloadFile(index, files_s3, resolve, files);
              });
            }

            files = files.map(f => {
              return {
                ...f,
                src: window.URL.createObjectURL(new Blob([f.blob]))
              };
            });

            files = _.sortBy(files, "name");
            resolve(files);
          };
        });
      });
    },

    // presentation
    async playCarrousel(reset = false) {
      if (!this.showPresentation) return;

      this.$emit("inPresentation", true);

      if (!reset) {
        if (this.localFiles.length < 1) {
          this.localFiles = await this.syncFiles();
        }

        // Elemento data
        let elem = this.localFiles[this.indexElement];

        if(!elem) return;
        this.item = elem;

        // showPresentation
        this.showPresentation = true;

        // manage presentation
        let type = elem.name.split(".")[1];

        // videos
        if (type == "mp4") {
          setTimeout(() => {
            var aud = document.getElementById(elem.id);
            if (aud) {
              aud.onended = () => {
                if (this.indexElement == this.localFiles.length - 1) {
                  this.indexElement = 0;
                  this.playCarrousel(true);
                } else {
                  this.indexElement++;
                  this.playCarrousel(false);
                }
              };
              aud.play();
            }
          }, 1000);

          // images
        } else {

          let time =
            elem.name.split("_").length > 1 ? elem.name.split("_")[1] : 1;
          if(time){
            time = typeof time == "string" ? time?.replace("s", "").split(".")[0] : 1;
          }

          setTimeout(() => {
            if (this.indexElement == this.localFiles.length - 1) {
              this.indexElement = 0;
              this.playCarrousel(true);
            } else {
              this.indexElement++;
              this.playCarrousel(false);
            }
          }, (time ? time : 1) * 1000);

        }
      } else {
        this.localFiles = await this.syncFiles();
        this.playCarrousel(false);
      }
    },

    downloadFile(index, filesS3, resolve, filesLocal) {

      // Check if file exists on local
      if (filesLocal.map(f => f.name).includes(filesS3[index].Key)) {

        // end loop if finish array
        if (index == filesS3.length - 1) {
          resolve();
        } else {
          index = index + 1;
          this.downloadFile(index, filesS3, resolve, filesLocal);
        }

        return;
      }

      // First - Download with axios
      axios({
        url: this.signObject(filesS3[index].Key),
        method: "GET",
        responseType: "blob" // important
      })
        .then(response => {
          const blobl = response.data;

          // SAVE ON LOCAL
          var item = {
            name: filesS3[index].Key,
            blob: blobl
          };

          // Init local database
          var transaction = this.dbObject.transaction(["files"], "readwrite");

          // START TRANSACTION ON LOCAL
          var filesStored = transaction.objectStore("files");

          let requestSaved = filesStored.add(item);
          // When local storage is done, we send the file to S3
          requestSaved.onsuccess = () => {
            if (index == filesS3.length - 1) {
              resolve();
            } else {
              index = index + 1;
              this.downloadFile(index, filesS3, resolve, filesLocal);
            }
          };
        })
        .catch(e => {
          console.log(e);
          if (index == filesS3.length - 1) {
            resolve();
          } else {
            index = index + 1;
            this.downloadFile(index, filesS3, resolve, filesLocal);
          }
        });
    },

    signObject(key) {
      const signedUrlExpireSeconds = 60 * 30; // your expiry time in seconds.
      return this.s3.getSignedUrl("getObject", {
        Bucket: this.bucketName,
        Key: key,
        Expires: signedUrlExpireSeconds
      });
    },

    stopPresentation(){
      this.showPresentation = false;
      setTimeout(() => {
        this.$emit("inPresentation", false);
      }, 500);
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showPresentation = true;
        this.$emit("inPresentation", true);
        // params reset
        this.playCarrousel(false);
      }, 10000);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_full {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(103 140 172);
  z-index: 10;
}

.message_important {
  width: 778px;
  position: fixed;
  bottom: 140px;
  right: 0;
  // transform: translateX(-50%);
  padding: 25px;
  text-align: center;
  // background-color: white;
  border-radius: 5px;
}

.message_important .Title {
  color: #1eaae6;
  font-size: 50px;
  filter: drop-shadow(0px 0px 30px rgb(255, 255, 255));
}

/** 9px 3.5rem */

.message_important span {
  margin: 0 10px;
  font-size: 50px;
}

.clicGif {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
</style>
