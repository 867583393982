import { render, staticRenderFns } from "./ConfirmUpload.vue?vue&type=template&id=a55accf4&scoped=true&lang=pug&"
import script from "./ConfirmUpload.vue?vue&type=script&lang=js&"
export * from "./ConfirmUpload.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmUpload.vue?vue&type=style&index=0&id=a55accf4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a55accf4",
  null
  
)

export default component.exports